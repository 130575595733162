<template>
  <v-card :loading="loading" elevation="0">
    <v-card-title
      class="card-header card-header-tab bg-white border-0 transparent"
    >
      <div
        :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
        class="card-title text-capitalize pt-3"
      >
        {{ $t("kiosk.lang_layoutSettings") }}
      </div>
    </v-card-title>

    <v-divider class="ma-0" />

    <v-card-text>
      <v-form ref="form" v-model="valide" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-switch
                v-model="form.kiosk_displayAlergenesAndAdditives"
                :label="$t('kiosk.lang_enableDisplayOfAlergenesAndAdditives')"
                dense
                outlined
                hide-details
                prepend-inner-icon="mdi-ticket-outline"
                class="mr-1"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="form.kiosk_displayNutritionTable"
                :label="$t('kiosk.lang_enableDisplayOfNutritionTable')"
                dense
                outlined
                hide-details
                prepend-inner-icon="mdi-ticket-outline"
                class="mr-1"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="form.kiosk_hideTapHereToStart"
                :label="$t('kiosk.lang_hideTapHereTopStart')"
                dense
                outlined
                hide-details
                prepend-inner-icon="mdi-ticket-outline"
                class="mr-1"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="border-top">
      <v-spacer />
      <v-btn :disabled="loading || !valide" @click="update()" color="success">
        {{ $t("generic.lang_apply") }}
      </v-btn>
    </v-card-actions>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        v-if="touchKeyboard.visible"
        id="onScreenKeyboard"
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
      />
    </div>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";

export default {
  name: "KioskLayoutSettingsComponent",
  mixins: [mixin],
  data() {
    return {
      loading: false,
      valide: false,
      form: {
        kiosk_displayAlergenesAndAdditives: false,
        kiosk_displayNutritionTable: false,
        kiosk_hideTapHereToStart: false,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.LAYOUT.GET)
        .then((res) => {
          if (res.data.STATUS == "SUCCESS") {
            this.form = Object.assign({}, res.data.data);
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.LAYOUT.UPDATE, this.form)
        .then((res) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_updatedSuccessfully"),
            color: "success",
          });
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
