<template>
    <v-sheet height="300" width="100%" ripple style="overflow: hidden" :style="customStyle" class="position-relative"
        elevation="1">
        <v-carousel v-model="current" height="300" hide-delimiter-background hide-delimiters :show-arrows="false"
            :cycle="true" :interval="interval">
            <template v-if="component.assets.length > 0">
                <v-carousel-item v-for="(asset, index) in component.assets" :key="index"
                    reverse-transition="fade-transition" transition="fade-transition">
                    <v-img v-if="asset.type === 0" aspect-ratio="1" :src="assetImage(asset.src)" width="100%"
                        max-height="100%" position="center center">
                        <template v-slot:placeholder>
                            <div class="text-muted text-center w-100 h-100 d-flex align-center justify-center">
                                <font-awesome-icon :icon="['fad', 'image']" size="2x" />
                            </div>
                        </template>
                    </v-img>
                    <video v-else-if="current === index" preload="auto" :key="'vbanner-video-' + asset.src" height="100%"
                        autoplay loop muted class="w-100" playsinline>
                        <source :src="asset.url">
                    </video>
                </v-carousel-item>
            </template>
            <template v-else>
                <v-carousel-item v-for="i in 3" :key="i" reverse-transition="fade-transition" height="100%"
                    transition="fade-transition">
                    <div class="text-muted text-center w-100 h-100 d-flex align-center justify-center">
                        <font-awesome-icon v-if="i === 1" :icon="['fad', 'photo-video']" size="4x" />
                        <font-awesome-icon v-else-if="i === 2" :icon="['fad', 'image']" size="4x" />
                        <font-awesome-icon v-else :icon="['fad', 'video']" size="4x" />
                    </div>
                </v-carousel-item>
            </template>
        </v-carousel>
        <!-- <v-img aspect-ratio="1" src="@/assets/images/kiosk/banner-mock.png" width="100%" max-height="100%"
            position="center center" @error="frontPageBannerError = true" /> -->

    </v-sheet>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapGetters } from 'vuex';

export default {
    name: "Banner",
    components: { FontAwesomeIcon },
    props: {
        component: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            current: 0,
        }
    },
    computed: {
        ...mapGetters({
            imageUrl: "api/auth/imageUrl",
        }),
        assetImage(){
            return (src)=> {
                if(!src)
                    return null;

                if(src.includes('data:image/'))
                    return src;

                return this.imageUrl(src);
            }
        },
        assets() {
            if (this.component.assets.length < 1)
                return null;

            return this.component.assets;
        },
        interval(){
            if(this.component.assets.length < 1 && this.current > -1)
                return 5000

            return this.component.assets[this.current]?.timer >= 2 ? this.component.assets[this.current]?.timer * 1000 : 5000
        },
        customStyle(){
            return {
                backgroundColor: this.component.style.backgroundColor + ' !important',
                borderStyle: 'solid',
                borderColor: this.component.style.borderColor + ' !important',
                borderWidth: this.component.style.borderWidth + 'px !important',
                borderRadius: this.component.style.borderRadius + 'px !important',
            }
        }
    }
}

</script>